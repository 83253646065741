import 'hammerjs'


// We are dealing with three image sizes in this component:
// 1. thumbnail
// 2. large - main product image
// 3. huge - gallery for zooming
//
// On the initial load we fetch all the product thumbnails and the
// first product's 'large' image. When the user clicks on a product
// thumbnail we fetch it's 'large' product image. When a user clicks
// on the 'large' product image to open the gallery, which fetch the
// 'huge' product image for zooming.

let images = {};
let currentId;

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('[data-product-image]')) {
    init();
  }
});

export default function init() {
  const container = document.querySelector('[data-product-image-container]');
  const thumbnails = document.querySelectorAll('[data-product-thumbnail]');
  const mainImage = document.querySelector('[data-product-image]');
  const hammer = new Hammer(container);
  const hasManyImages = thumbnails.length > 0;

  if (container === null) return;

  currentId = thumbnails[0];
  images[currentId] = mainImage;

  if (hasManyImages) {
    hammer.on('swiperight', prev);
    hammer.on('swipeleft', next);

    [...thumbnails].forEach(thumbnail => {
      // Initially we load the first hero product image only
      // The thumbnail's data attributes contain the urls for the
      // larger images, which we store and load when needed.
      preloadImage(thumbnail);
      // Set new image when a thumbnail is clicked
      thumbnail.addEventListener('click', () => set(thumbnail.id));
    });
  }

  // When we load the Product Gallery we pass it the 'zoom' image
  // url (i.e. the 'huge' size) from our images array
  container.addEventListener('click', () => {
    const imageUrl = hasManyImages ?
            images[currentId].getAttribute('data-src-zoom') :
            mainImage.getAttribute('data-src-zoom');

    window.StoreConnectUI.ProductGallery.init(imageUrl)
  });
}

export function set(id) {
  const image = document.querySelector('[data-product-image]');
  const thumbnails = document.querySelectorAll('[data-product-thumbnail]');

  // Replace the existing image from array of preloaded images
  // and update the active state for all thumbnails
  currentId = id;

  image.replaceWith(images[id]);

  if (thumbnails.length > 0) {
    [...thumbnails].forEach(thumb => {
      thumb.id == id ?
        thumb.classList.add('is-active') :
        thumb.classList.remove('is-active');
    });
  }
}

export function next() {
  set(
    document.querySelector(
      `[data-product-thumbnail="${currentId}"]`
    ).nextSibling.id
  );
}

export function prev() {
  set(
    document.querySelector(
      `[data-product-thumbnail="${currentId}"]`
    ).previousSibling.id
  );
}

function preloadImage(thumbnail) {
  let media;
  if(thumbnail.getAttribute('data-video-src')) {
    media = document.createElement('div');
    media.className = 'sc-responsive-iframe';
    media.innerHTML = thumbnail.getAttribute('data-video-src')
    media.setAttribute('data-product-image', '')
  } else {
    media = new Image();

    media.src = thumbnail.getAttribute('data-src');
    media.alt = thumbnail.getAttribute('alt');
    media.setAttribute('data-product-image', thumbnail.getAttribute('data-product-thumbnail'));
    media.setAttribute('data-src-zoom', thumbnail.getAttribute('data-src-zoom'));
  }
  images[thumbnail.id] = media;
}
