import 'ion-rangeslider'
import { mediumAndUp } from './viewport'

$(function() {
  $('[data-js-range-slider]').ionRangeSlider({
    onFinish: function (data) {
      if(data.min != data.from || data.max != data.to) {
        $('[data-js-range-min]').val(data.from).attr('name', '[price][min]');
        $('[data-js-range-max]').val(data.to).attr('name', '[price][max]');
      } else {
        // Removing the name field from these attributes prevents them from polluting the URL
        // to make a cleaner, copyable, understandable URL field and improve search results
        $('[data-js-range-min]').removeAttr('name');
        $('[data-js-range-max]').removeAttr('name');
      }

      if (!mediumAndUp()) { return; }
      $('[data-filters]').submit();
    },
  });
});
