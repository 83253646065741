window.StoreConnectUI = window.StoreConnectUI || {};

window.StoreConnectUI.Sort = function() {
  document.addEventListener('DOMContentLoaded', function() {
    const input = document.querySelector('[data-filters] input[name=sort]');

    if (input) {
      document.querySelector('[data-sort] input[name=sort][value=' + input.value + ']').setAttribute('checked', true);
    }
  });

  return {
    update: id => {
      document.querySelector('[data-filters] input[name=sort]').value = id;
      document.querySelector('[data-filters]').submit();
    }
  }
}();
